//Core
import axios from 'axios';
import { CONTENTFUL_PATH } from '../../constants';
//Local
import {
  GET_PAGE_GOODS,
  SET_PAGE_GOODS,
  GET_PAGE_DIGITAL,
  SET_PAGE_DIGITAL,
  GET_PAGE_TRAVEL,
  SET_PAGE_TRAVEL,
  GET_PAGE_SUBSCRIPTION,
  SET_PAGE_SUBSCRIPTION,
  GET_PAGE_TOP,
  SET_PAGE_TOP,
  GET_COLUMN_ARTICLES,
  GET_COLUMN_HIGHLIGHT,
  SET_COLUMN_HIGHLIGHT,
  SET_COLUMN_ARTICLES,
} from './types';

//Sync
export const setPageGoods = (payload) => {
  return {
    type: SET_PAGE_GOODS,
    payload,
  };
};

export const setPageDigital = (payload) => {
  return {
    type: SET_PAGE_DIGITAL,
    payload,
  };
};

export const setPageTravel = (payload) => {
  return {
    type: SET_PAGE_TRAVEL,
    payload,
  };
};

export const setPageSubscription = (payload) => {
  return {
    type: SET_PAGE_SUBSCRIPTION,
    payload,
  };
};

export const setPageTop = (payload) => {
  return {
    type: SET_PAGE_TOP,
    payload,
  };
};

export const setColumnHighlight = (payload) => {
  return {
    type: SET_COLUMN_HIGHLIGHT,
    payload,
  };
};

export const setColumnArticles = (payload) => {
  return {
    type: SET_COLUMN_ARTICLES,
    payload,
  };
};

//Async
export const getPageGoods = () => async (dispatch) => {
  dispatch({ type: GET_PAGE_GOODS });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}page-merchant-goods.json`);

    dispatch(setPageGoods(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getPageDigital = () => async (dispatch) => {
  dispatch({ type: GET_PAGE_DIGITAL });

  try {
    const data = await axios.get(
      `${CONTENTFUL_PATH}page-merchant-digital.json`
    );

    dispatch(setPageDigital(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getPageTravel = () => async (dispatch) => {
  dispatch({ type: GET_PAGE_TRAVEL });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}page-merchant-travel.json`);

    dispatch(setPageTravel(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getPageSubscription = () => async (dispatch) => {
  dispatch({ type: GET_PAGE_SUBSCRIPTION });

  try {
    const data = await axios.get(
      `${CONTENTFUL_PATH}page-merchant-subscription.json`
    );

    dispatch(setPageSubscription(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getPageTop = () => async (dispatch) => {
  dispatch({ type: GET_PAGE_TOP });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}page-merchant-top.json`);

    dispatch(setPageTop(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getColumnArticles = () => async (dispatch) => {
  dispatch({ type: GET_COLUMN_ARTICLES });

  try {
    const columns = await axios.get(`${CONTENTFUL_PATH}column.json`);
    const caseStudiesData = await axios.get(
      `${CONTENTFUL_PATH}case-studies.json`
    );

    const caseStudies = caseStudiesData.data
      .filter((caseStudy) => caseStudy.tags && caseStudy.tags.length > 0)
      .map((caseStudy) => {
        return {
          fields: {
            title: caseStudy.merchant.shopName,
            subtitle: caseStudy.title,
            previewImage: {
              fields: {
                file: {
                  url: caseStudy.thumbnailMobile.url,
                },
              },
            },
            tags: caseStudy.tags,
          },
          sys: { id: caseStudy.slug, type: 'CaseStudy' },
        };
      });

    dispatch(setColumnArticles([...columns.data, ...caseStudies]));

    return Promise.resolve(columns);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};

export const getColumnHighlight = () => async (dispatch) => {
  dispatch({ type: GET_COLUMN_HIGHLIGHT });

  try {
    const data = await axios.get(`${CONTENTFUL_PATH}media-center-column.json`);

    dispatch(setColumnHighlight(data.data[0].fields));

    return Promise.resolve(data);
  } catch (error) {
    console.log(error.message);

    return Promise.reject(error);
  }
};
